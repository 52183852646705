<template>
  <section class="all_program_model">
    <el-row>
      <el-col :xs="4" :sm="4" :md="3" class="pr-2 pb-2 phoneBack">
        <el-button icon="el-icon-arrow-left" style="color: #50b5ff" @click="goback()">
          {{ $t("Events.024@back") }}</el-button>
      </el-col>
      <!-- <el-col :span="15" class="phoneSchedule pl-5" v-if="single_schedule.length">
        <iq-card class="cardStyle">
          <div class="mb-2 title d-flex justify-content-between">
            <div>
              <i class="ri-calendar-2-line mr-2"></i>
              {{ $t("Events.31b@my_schedule") }}
            </div>
            <el-tooltip effect="light" enterable>
              <div slot="content" style="min-width:200px">
                <div>
                  <ul class="p-0 eventList">
                    <li class="event" v-for="(v, index) in single_schedule" :key="index">
                      <h5 style="cursor: pointer" class="title" @click="toProgramDetail(v)">
                        {{ v.program_title }}
                      </h5>
                      <div style="font-size:12px;padding:5px">{{ v.time_zone_2 }}</div>
                      <div style="font-size:12px;padding:5px">{{ v.time2 }}</div>
                      <div style="font-size:12px;padding:5px">{{ v.time1 }}</div>
                      <hr />
                    </li>
                  </ul>
                </div>
              </div>
              <span style="line-height:25px">
                <i class="el-icon-arrow-down"></i>
              </span>
            </el-tooltip> 
          </div>
        </iq-card>
      </el-col> -->
      <el-col :xs="24" :sm="14" class="pb-3">
        <div class="card p-2">
          <tab-nav :tabs="true" id="myTab-two">
            <tab-nav-items :active="true" href="#package" title="Package">
            </tab-nav-items>
          </tab-nav>
          <tab-content>
            <tab-content-item :active="true" id="package" aria-labelled-by="prerecording-tab-two">
              <div v-if="
                  packageList.custom_package &&
                  packageList.custom_package.length > 0
                ">
                <div v-if="
                    packageList.custom_package &&
                    packageList.custom_package.length
                  ">
                  <div class="processBox">
                    <div :class="
                        currentClickNumber > 0 ? 'arrow' : 'arrow arrowOpacity'
                      " @click="fnPrev()" v-show="packageList.custom_package.length > 1">
                      <i class="el-icon-arrow-left"></i>
                    </div>
                    <div class="fixedBox" :ref="`fixedBox`">
                      <div class="centerScroll" :style="`width:${
                          signleWidth * packageList.custom_package.length
                        }px;transform:translate(${scrollResultWidth}px,0);transition:1s;`">
                        <div class="top-list">
                          <div class="signleTab" :class="{ backGrc: v.is_buy }" @click="checkPack(v, i)"
                            v-for="(v, i) in packageList.custom_package" :key="i + 'progress'">
                            <p :title="v.setmeal_title">
                              {{ v.setmeal_title }}
                            </p>

                            <p>
                              {{v.is_other_price?"€":"$"}}{{ v.price }}
                              <span v-if="v.birldTime == 2" style="color: #ff9a50">({{v.is_other_price?"€":"$"}}{{ v.bird_price }})</span>
                            </p>
                            <div v-if="squreSlect == i" class="backBlue"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="noScrollRight ? 'arrow' : 'arrow arrowOpacity'" @click="fnNext(activeName)"
                      v-show="packageList.custom_package.length > 1">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between pt-3" style="border-top: 1px solid #eee">
                  <section class="d-flex align-items-center">
                    <div v-if="selDetail.is_other_price" class="mr-2">
                    </div>
                    <section class="ml-2">
                      <div v-if="$route.query.type==2">
                        <div class="price" v-show="selDetail.birldTime == 1">
                          <span  style="color: #333">{{selDetail.is_other_price?"€":"$"}}{{ filterPrice }}</span>
                          <span style="color: #ef5959">${{ bridePrice }}</span>
                          <el-tooltip class="item" effect="dark" :content="`Bird Time: ${selDetail.bird_time} `"
                            placement="top-start">
                            <i class="ri-information-line"
                              style="font-size: 20px;color: #50b5ff;text-decoration: none;"></i>
                          </el-tooltip>
                        </div>
                        <div class="price" v-show="selDetail.birldTime == 2">
                          <span style="color: #ef5959">{{selDetail.is_other_price?"€":"$"}}{{ bridePrice }}</span>
                          <span style="color: #333; text-decoration: line-through">{{selDetail.is_other_price?"€":"$"}}{{ filterPrice }}</span>
                          <el-tooltip class="item" effect="dark" :content="`Bird Time: ${selDetail.bird_time} `"
                            placement="top-start">
                            <i class="ri-information-line"
                              style="color: #50b5ff;text-decoration: none;"></i>
                          </el-tooltip>
                        </div>
                        <div class="price" v-show="selDetail.birldTime == 3">
                          <span style="color: #333">{{selDetail.is_other_price?"€":"$"}}{{ filterPrice }}</span>
                          <span></span>
                        </div>
                      </div>
                      <div v-else>
                        <div class="price" v-show="selDetail.birldTime == 1">
                          <span style="color: #333">${{ selDetail.price }}</span>
                          <span style="color: #ef5959">${{ selDetail.bird_price }}</span>
                          <el-tooltip class="item" effect="dark" :content="`Bird Time: ${selDetail.bird_time} `"
                            placement="top-start">
                            <i class="ri-information-line"
                              style="color: #50b5ff;text-decoration: none;"></i>
                          </el-tooltip>
                        </div>
                        <div class="price" v-show="selDetail.birldTime == 2">
                          <span style="color: #ef5959">${{ selDetail.bird_price }}</span>
                          <span style="color: #999; text-decoration: line-through">${{ selDetail.price }}</span>
                          <el-tooltip class="item" effect="dark" :content="`Bird Time: ${selDetail.bird_time} `"
                            placement="top-start">
                            <i class="ri-information-line"
                              style="color: #50b5ff;text-decoration: none;"></i>
                          </el-tooltip>
                        </div>
                        <div class="price" v-show="selDetail.birldTime == 3">
                          <span style="color: #333">${{ selDetail.price }}</span>
                          <span></span>
                        </div>
                      </div>
                    </section>
                  </section>
                  <el-input-number step-strictly v-if="!selDetail.is_buy && !selDetail.is_pay_online&&selDetail.is_edit" style="width:130px; margin-right:10px"  v-model="num" @change="handleChange" :min="1"></el-input-number>
                  <el-button v-if="!selDetail.is_buy && !selDetail.is_pay_online&&selDetail.is_edit" type="warning"
                    @click="payConf()" class="checkBtn">Check out</el-button>
                  <el-button v-else-if="!selDetail.is_buy && selDetail.is_pay_online" class="checkBtn" type="info" disabled
                    >Payment Pending Review</el-button>
                  <el-button v-if="selDetail.is_buy" type="info" class="checkBtn" disabled >Purchased
                  </el-button>
                </div>
              </div>
              <div v-else style="background:#fff;" class="container690">
                <img src="@/assets/images/group/empty1.png" alt="" style="width:250px;margin: 100px auto; display: block" />
              </div>
            </tab-content-item>
          </tab-content>
        </div>
        <div v-if="
            packageList.custom_package && packageList.custom_package.length > 0
          ">
          <div class="mt-3" v-if="!Array.isArray(allProgram)">
            <el-card>
              <div class="program-header d-flex justify-content-between">
                <div class="d-flex">
                  <!-- <i class="ri-calendar-2-line"></i> -->
                  <p class="m-0">
                    {{ Object.keys(this.allProgram)[index] }}
                    <span style="color: #b1b6ba">({{
                      allProgram[Object.keys(allProgram)[index]] &&
                      allProgram[Object.keys(allProgram)[index]][0]
                      .time_zone_2
                      }})</span>
                  </p>
                </div>
                <div class="program-btn">
                  <el-button :type="index ? '' : 'info'" plain :disabled="!index" style="border: none"
                    @click="changeIndex(0)">
                    {{ $t("Events.99e@previous") }}</el-button>
                  <el-button :type="
                      index != Object.keys(this.allProgram).length - 1
                        ? ''
                        : 'info'
                    " plain :disabled="index == Object.keys(this.allProgram).length - 1"
                    style="border: none;" @click="changeIndex(1)">
                    Next Day
                    <!-- {{ $t("Events.b72@next") }} -->
                  </el-button>
                </div>
              </div>
              <div>
                <section v-for="(v, i) in allProgram[
                    Object.keys(this.allProgram)[index]
                  ]" :key="i" class="program-content">
                  <ul class="p-0 program-list">
                    <li>
                      <div>
                        <h3 style="cursor: pointer" @click="toProgramDetail(v)">
                          {{ v.program_title }}
                          <span :class="{
                              MeetingTag: v.model_type == 4,
                              EventTag: v.model_type == 2,
                              courseTag: v.model_type == 3,
                              projectTag: v.model_type == 1,
                              noneTag: v.model_type == 0,
                            }" class="tag" v-if="v.is_cron">
                            {{ judgeCurrentType(v) }}
                          </span>
                          <span class="ml-2">({{ v.program_count }}/{{ v.max_attendee }})</span>
                        </h3>
                        <span><i class="ri-time-line"></i>{{ v.time1 }}
                          <!-- <span style="color: #b1b6ba">({{ v.time_zone }})</span> -->
                        </span>
                        <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                          <span v-if="v.type == 3">
                            <i class="ri-map-pin-line"></i>{{ v.venue }}
                            {{ v.room }} / Online
                          </span>
                          <span v-else-if="v.type != 2 && v.room"><i class="ri-map-pin-line"></i>{{ v.venue }}
                            {{ v.room }}</span>
                          <span v-else-if="v.type_desc"><i class="ri-map-pin-line"></i>{{ v.type_desc }}</span>
                        </section>
                        <span><i class="ri-lock-unlock-line"></i>{{ Object.values(v.attendee_tags).join("，") }}
                        </span>
                        <span v-if="v.special_guest.length"><i class="ri-user-3-line"></i>
                          {{ v.special_guest.map((n) => n.name).join("，") }}
                        </span>
                        <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                          <span v-if="v.type != 1" class="d-flex align-items-center">
                            <i v-if="v.broadcast_link || v.conference_link" class="ri-link"></i>
                            <div class="d-flex flex-column">
                              <span v-if="v.broadcast_link" @click="userHasPowerClickLink(v)" class="link_blue">{{
                                $t("Events.dcf@click_here_to_view") }}</span>
                              <a class="link_blue" @click="conferLinkLink(v.conference_link)" v-if="v.conference_link"
                                target="__blank">{{ $t("Events.e6e@click_here_to_join_c") }}</a>
                            </div>
                          </span>
                        </section>
                        <span class="d-flex" v-if="v.file_array && v.file_array.length">
                          <i class="ri-folder-2-line"></i>
                          <div class="d-flex flex-column">
                            <a :href="fv" target="_blank" v-for="(fv, fi) in v.file_array" :key="fi" class="mb-2" style="
                                text-decoration: underline;
                                margin-top: 10px;
                              ">{{ fv }}</a>
                          </div>
                        </span>
                      </div>
                      <div v-if="!v.my_schedule" class="i-tooltips">
                        <el-tooltip class="item" effect="light" content="Add to my Schedule" placement="left">
                          <i v-show="v.is_buy" @click="addToMyChedule(v)" class="ri-add-circle-line"></i>

                          <div style="cursor: pointer" slot="content">
                            <span class="addSchduleTitle mb-5">Add to my Schedule</span>
                            <p class="addSchduleTime m-0">
                              Before {{ v.end_time }}
                            </p>
                          </div>
                        </el-tooltip>
                      </div>

                      <div v-else class="i-remove">
                        <el-tooltip class="item" effect="light" content="Remove" placement="left">
                          <i @click="delMySchedule(v)" class="ri-indeterminate-circle-line"></i>
                        </el-tooltip>
                      </div>
                    </li>
                  </ul>
                </section>
              </div>
            </el-card>
          </div>
          <div v-else style="background:#fff;" class="container690">
            <el-card class="mt-3">
              <img src="@/assets/images/group/empty1.png" alt="" style="width:250px;margin: 100px auto; display: block" />
            </el-card>
          </div>
        </div>
      </el-col>
      <!-- <el-col :xs="24" :sm="6" class="RightSidebar pl-3" v-if="single_schedule.length">
        <iq-card class="cardStyle">
          <div class="mb-2 title">
            <i class="ri-calendar-2-line mr-2"></i>
            {{ $t("Events.31b@my_schedule") }}
          </div>
          <div>
            <ul class="p-0 eventList">
              <li class="event" v-for="(v, index) in single_schedule" :key="index">
                <div style="cursor: pointer" class="title" @click="toProgramDetail(v)">
                  {{ v.program_title }}
                </div>
                <div class="dateTime">{{ v.time_zone_2 }}</div>
                <div class="dateTime">{{ v.time2 }}</div>
                <div class="dateTime">{{ v.time1 }}</div>
                <hr />
              </li>
            </ul>
          </div>
        </iq-card>
      </el-col> -->
    </el-row>
    <b-modal id="payConfirm" title="Activity rules" hide-footer>
      <div>{{ refund_instruction }}</div>
      <div style="border-top: 1px solid #eee; margin-top: 20px; padding-top: 10px">
        <el-button style="float: right; margin-left: 10px" type="primary" @click="payPage()">
          <section v-if="selDetail.birldTime==2">
            {{selDetail.bird_price=='0.00'?'Confirm':'Check out'}}
          </section>
          <section v-else>
            {{selDetail.price=='0.00'?'Confirm':'Check out'}}
          </section>
        </el-button>
        <el-button style="float: right" type="info" @click="cancelPay">{{
          $t("Events.54c@cancel")
          }}</el-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  export default {
    data() {
      return {
        packageList: {
          is_register: true
        },
        allProgram: {},
        squreSlect: 0,
        selDetail: {},
        single_schedule: [], // 已加入的行程
        is_refund: 0,
        refund_instruction: '',
        // birldTime: 0,

        scrollResultWidth: 0,
        currentClickNumber: 0,
        signleWidth: 280,
        activeName: 0,
        noScrollRight: true,
        num: 1,
      }
    },
    watch: {
      // selDetail: {
      //   handler(val) {
      //     if (!val) return;
      //     let birldTimeStart = this.selDetail.bird_start_time;
      //     let birldTimeEnd = this.selDetail.bird_end_time;
      //     var date = new Date();
      //     var now = date.getTime();
      //     var start = new Date(birldTimeStart).getTime();
      //     var end = new Date(birldTimeEnd).getTime();
      //     let isBirldStart = now - start;
      //     let isBirldEnd = now - end;
      //     if (isBirldStart < 0) {
      //       // 说明早鸟时间还没开始，显示时间段，2
      //       this.birldTime = 1;
      //       // return 1
      //     } else if (isBirldStart > 0 && isBirldEnd < 0) {
      //       // 早鸟时间已经开始,倒计时是距离结束时间的倒计时
      //       this.birldTime = 2;
      //     } else {
      //       // 早鸟时间已经结束或者不存在早鸟时间
      //       this.birldTime = 3;
      //       // return 3
      //     }
      //   },
      //   deep: true,
      // },
    },
    components: {},
    filters: {
      jumpModelFilter(val) {
        // console.log("cal", val);
        return val == 1
          ? vm.$t('Events.135@events')
          : val == 2
            ? 'Event'
            : val == 3
              ? 'Course'
              : val == 4
                ? 'Meeting'
                : ''
      },
      filterTime(val) {
        return val.replace(/00:00/g, ' ')
      }
    },
    computed: {
      filterPrice(){
        let price = (Number(this.selDetail.price) * this.num).toFixed(2)
        return price
      },
      bridePrice(){
        let pri = (Number(this.selDetail.bird_price) * this.num).toFixed(2)
        return pri
      },
      ...mapState({
        is_register: (state) => {
          return state.Process.is_register
        },
        // allProgram: (state) => {
        //   return state.Process.all_program;
        // },
        index: (state) => {
          if (state.Process.tabIndex >= 1) {
            return state.Process.tabIndex
          } else {
            return 0
          }
        },

        typeId: (state) => state.Process.typeId
      })
    },
    mounted() {
      this.getPackageList()
      this.eventProgram(4)
      this.checkIndex()
      this.$nextTick(() => {
        setTimeout(() => {
          this.initgoRightArrow()
        })
      })
    },

    methods: {
      ...mapMutations([
        'changeIndex',
        'checkIndex',
        'clearCheckArray',
        'cancelPay'
      ]),
      ...mapActions(['projectProgram', 'projectAddProgram']),

    handleChange(value) {
      console.log(this.selDetail);
    },
      handlerCommand(val) {
        this.selDetail.chose_pay = val
      },

      goback() {
        let type = this.$route.query.type
        let path = {
          1: '/project-detail',
          2: '/event-detail',
          3: '/course-detail',
          4: '/meeting-details'
        }
        this.$router.push({
          path: path[type],
          query: {
            id: this.$route.query.id,
            type: type
          }
        })
      },
      initgoRightArrow() {
        const currentScrollWidth = this.$refs[`fixedBox`].clientWidth
        const canNumber = Math.floor(currentScrollWidth / this.signleWidth) // 可以放下的个数
        // 如果最后一个流程图标已经展示出来，则停止滚动
        if (
          this.currentClickNumber + canNumber >=
          this.packageList.custom_package.length
        ) {
          this.noScrollRight = false
        }
      },
      fnPrev() {
        // 如果右点击的次数大于0，才可以左滚
        if (this.currentClickNumber > 0) {
          this.currentClickNumber -= 1
          this.noScrollRight = true
          this.fnScrollWidth('reduce')
        } else {
          return false
        }
      },
      // 点击下一个
      fnNext() {
        const currentScrollWidth = this.$refs[`fixedBox`].clientWidth
        const canNumber = Math.floor(currentScrollWidth / this.signleWidth) // 可以放下的个数
        // 如果最后一个流程图标已经展示出来，则停止滚动
        if (
          this.currentClickNumber + canNumber >=
          this.packageList.custom_package.length
        ) {
          return
        }
        // 说明放不下有滚动条
        if (this.packageList.custom_package.length > canNumber) {
          this.currentClickNumber += 1
          if (
            this.currentClickNumber + canNumber >=
            this.packageList.custom_package.length
          ) {
            this.noScrollRight = false
          }
          this.fnScrollWidth('add')
        }
      },
      // translate的宽度
      fnScrollWidth(type) {
        let result = 0
        if (type === 'reduce') {
          result = 280
        } else if (type === 'add') {
          result = -280
        } else {
          result = 0
        }
        this.scrollResultWidth += result
      },

      checkPack(v, i) {
        if (this.squreSlect == i) return
        this.checkIndex()
        this.num = 1;
        this.squreSlect = i
        this.selDetail = v
        this.selDetail.chose_pay = 0
        this.eventProgram(2)
        this.setPopupText()
      },
      setPopupText() {
        let type = this.$route.query.type
        let popupText
        let modelText = { 2: 'event', 4: 'meeting' }[type]
        if (this.selDetail.birldTime == 2) {
          popupText = this.selDetail.bird_price == '0.00' ? `Are you sure you want to attend the ${modelText}?` : this.$t("Events.cec@please_be_reminded_t")
        } else {
          popupText = this.selDetail.price == '0.00' ? `Are you sure you want to attend the ${modelText}?` : this.$t("Events.cec@please_be_reminded_t")
        }
        this.refund_instruction = this.is_refund
          ? this.res_refund_instruction
          : popupText
      },
      conferLinkLink(link) {
        // console.log(link.indexOf("http"));
        if (link.indexOf('http') >= 0) window.open(link, '_blank')
        if (link.indexOf('http') < 0) window.open(`http://${link}`, '_blank')
      },
      async userHasPowerClickLink(item) {
        let ajaxType = {
          1: 'userHasPowerClickLink',
          2: 'eventUserHasPowerClickLink',
          3: 'courseUserHasPowerClickLink',
          4: 'meetingUserHasPowerClickLink'
        }
        let typeId = {
          1: 'project_id',
          2: 'event_id',
          3: 'course_id',
          4: 'meeting_id'
        }
        let type = this.$route.query.type
        let token = localStorage.getItem('token')
        let getToken = token || ''
        if (!getToken) {
          this.$router.push('/')
          return
        }
        let res = await this.$http[ajaxType[type]]({
          [typeId[type]]: item.model_id,
          my_schedule_id: item.my_schedule_id,
          my_schedule_model_type: item.my_schedule_model_type,
          link_type: 'broadcast_link'
        })
        if (res.status == 200) {
          // vimeo.com就在项目里，外链跳出去
          if (item.broadcast_link.indexOf('vimeo.com') > 0) {
            this.$router.push({
              path: type == 2 ? '/video-chat' : '/video',
              // modelType ==2，跳'video-chat'
              query: {
                id: item.model_id,
                my_schedule_id: item.my_schedule_id,
                type: this.$route.query.type,
                my_schedule_model_type: item.my_schedule_model_type,
                program_id: item.program_id,
                src: item.broadcast_link
              }
            })
          } else {
            if (item.broadcast_link.indexOf('http') >= 0) { window.open(item.broadcast_link, '_blank') }
            if (item.broadcast_link.indexOf('http') < 0) { window.open(`http://${item.broadcast_link}`, '_blank') }
            // window.open(item.broadcast_link, '_blank')
          }
        }
      },
      getPackageList() {
        this.squreSlect = 0
        let type = this.$route.query.type
        let typeId = {
          1: 'project_id',
          2: 'event_id',
          3: 'course_id',
          4: 'meeting_id'
        }
        let ajaxType = {
          1: 'packageList',
          2: 'eventPackageList',
          3: 'coursePackageList',
          4: 'meetingPackageList'
        }
        this.$http[ajaxType[type]]({
          [typeId[type]]: this.$route.query.id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
        }).then((res) => {
          res.data.custom_package.forEach((item) => {
            this.$set(item, 'chose_pay', 0)
            let birldTimeStart = item.bird_start_time
            let birldTimeEnd = item.bird_end_time
            let date = new Date()
            let now = date.getTime()

            let start = new Date(birldTimeStart).getTime()
            let end = new Date(birldTimeEnd).getTime()

            let isBirldStart = now - start
            let isBirldEnd = now - end
            if (isBirldStart < 0) {
              item.birldTime = 1
            } else if (isBirldStart > 0 && isBirldEnd < 0) {
              item.birldTime = 2
            } else {
              item.birldTime = 3
            }
          })
          this.packageList = res.data
          // console.log("this.packageList", this.packageList);
          this.is_refund = res.data.is_refund
          this.res_refund_instruction = res.data.refund_instruction
          // this.refund_instruction = this.is_refund
          //   ? res.data.refund_instruction
          //   : this.$t('Events.cec@please_be_reminded_t')
          if (res.data.custom_package?.length > 0) {
            this.selDetail = res.data.custom_package[0]
            this.eventProgram(2)
            this.setPopupText()
          }
        })
      },
      eventProgram(v) {
        this.allProgram = []
        let type = this.$route.query.type
        if (!type) return
        let typeId = {
          1: 'project_id',
          2: 'event_id',
          3: 'course_id',
          4: 'meeting_id'
        }
        let ajaxType = {
          1: 'projectProgram',
          2: 'eventProgram',
          3: 'courseProgram',
          4: 'meetingProgram'
        }
        this.$http[ajaxType[type]]({
          [typeId[type]]: this.$route.query.id,
          type: v,
          setmeal_id: this.selDetail.setmeal_id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
          .then((res) => {
            if (v == 4) {
              this.single_schedule = res.data.programs || []
            } else {
              this.allProgram = res.data.programs
            }
          })
      },
      payConf() {
        this.$bvModal.show('payConfirm')
      },
      payPage() {
        console.log(this.selDetail,this.selDetail.chose_pay);
        let modelType = this.$route.query.type
        let typeId = {
          1: 'project_id',
          2: 'event_id',
          3: 'course_id',
          4: 'meeting_id'
        }
        let ajaxType = {
          1: 'payPackage',
          2: 'eventPayPackage',
          3: 'coursePayPackage',
          4: 'meetingPayPackage'
        }
        let setmeal = []
        setmeal.push(this.selDetail.setmeal_id)
        let data;
        if(modelType==2||modelType==4){
          data={
            [typeId[modelType]]: this.$route.query.id,
            order_type: 1,
            program: this.checkedList,
            setmeal_id: this.selDetail.setmeal_id,
            price_unit: this.selDetail.is_other_price,
            time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
            number: this.num
          }
        }else{
          data={
            [typeId[modelType]]: this.$route.query.id,
            order_type: 1,
            program: this.checkedList,
            setmeal: setmeal,
            [modelType == 2 ? 'price_unit' : modelType == 4 ? 'price_unit' : '']: this.selDetail.is_other_price,
            time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        }
        this.$http[ajaxType[modelType]](data).then((res) => {
          if (res.status == 200) {
            // this._packageList();
            this.getPackageList()
            this.clearCheckArray()
            if (res.data.is_jump == 1) {
              this.$router.push({
                path: `/PayMoney`,
                query: {
                  model_type: modelType,
                  id: modelType == 2 ? res.data.id : modelType == 4 ? res.data.id : res.data.order_id,
                  model_id: this.$route.query.id,
                  package_type: 2
                }
              })
            } else if (res.data.is_jump == 0) {
              this.$router.push({
                path: `/PaySuccess`,
                query: {
                  model_type: modelType,
                  package_type: 2,
                  model_id: this.$route.query.id,
                  // typeStatus: 1
                }
              })
            }
          }
        })
        this.$bvModal.hide('payConfirm')
      },
      judgeCurrentType(item) {
        let type = item.model_type
        return type == 1
          ? 'Project'
          : type == 2
            ? 'Event'
            : type == 3
              ? 'Course'
              : type == 4
                ? 'Meeting'
                : ''
      },
      toProgramDetail(item) {
        let path = {
          1: '/project-detail',
          2: '/event-detail',
          3: '/course-detail',
          4: '/meeting-details'
        }
        if (item.is_cron) {
          this.$router.push({
            path: path[item.model_type],
            query: {
              id: item.model_id,
              type: item.model_type
            }
          })
        }
      },
      async addToMyChedule(item) {
        // console.log(item);
        let type = this.$route.query.type
        let ajaxType = {
          1: 'projectAddProgram',
          2: 'eventAddProgram',
          3: 'courseAddProgram',
          4: 'meetingAddProgram'
        }
        let res = await this.$http[ajaxType[type]]({
          my_program_id: item.my_program_id,
          my_program_model_type: item.my_program_model_type
        })
        if (res.status == 200) {
          this.eventProgram(4)
          this.eventProgram(2)
        }
      },

      // 删除个人行程
      async delMySchedule(item) {
        let type = this.$route.query.type
        let ajaxType = {
          1: 'projectDeleteProgram',
          2: 'eventDeleteProgram',
          3: 'courseDeleteProgram',
          4: 'meetingDeleteProgram'
        }
        let res = await this.$http[ajaxType[type]]({
          my_schedule_id: item.my_schedule_id,
          my_schedule_model_type: item.my_schedule_model_type
        })
        if (res.status == 200) {
          this.eventProgram(4)
          this.eventProgram(2)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .el-input-number .el-input__inner{
    padding-left: 20px;
    padding-right: 20px;
  }
::v-deep .el-input-number__decrease{
    width: 35px;
  }
@media (min-width: 770px){
.phoneSchedule{
    display: none;
  }
  .RightSidebar {
    display: block;
  }
}
@media (max-width: 770px){
  .phoneSchedule{
    display: block;
    margin-left: 10px;
    .title{
      padding: 7px;
    }
  }
  .RightSidebar {
    display: none;
  }
}
@media (min-width: 576px) {
  .all_program_model {
    .event_select {
      background: #f5fbff;
      padding: 1px 15px 0.5px;
      border-radius: 5px;
    }
    .checkBtn{
      width: 240px; 
      height: 40px
    }
  }
  .program-header {
    display: flex;
    padding: 10px;
    background-color: #f5fbff;
    align-items: center;

    >div i {
      font-size: 16px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    >div p {
      color: #666;
      font-size: 16px;
    }

    >div.program-btn {
      /* display: flex; */
      /* flex-grow: 1; */
      /* justify-content: flex-end; */
      .el-button{
        font-size: 12px;
        font-weight: 400;
        padding: 10px;
      }

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .top-list {
    display: -webkit-box;
    flex-wrap: nowrap;

    div {
      width: 280px;
      height: 100px;
      margin-bottom: 20px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 10px 10px;
      color: #50b5ff;
      background-color: #f5fbff;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      p {
        margin: 0;
        line-height: 30px;
        font-size: 16px;
      }

      p:nth-child(1) {
        font-size: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
      }
    }

    .backGrc {
      background-color: #f5f5f5;
    }

    .backBlue {
      position: absolute;
      top: 0;
      right: 0;
      width: 28px;
      height: 28px;
      padding: 0;
      margin: 0;
      border-top: 15px solid #50b5ff;
      border-right: 15px solid #50b5ff;
      border-left: 15px solid rgba(#000, 0.01);
      border-bottom: 15px solid rgba(#000, 0.01);
    }
  }

  .price {
    >span {
      &:first-child {
        font-size: 24px;
        font-weight: 500;
      }

      &:nth-child(2) {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    i{
      font-size: 20px;
    }
    
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      >li {
        padding: 10px;
        padding-left: 64px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 14px;
          left: 26px;
        }

        >div {
          display: flex;
          flex-direction: column;

          .link_blue {
            text-decoration: underline;
            color: #50b5ff;
            padding: 0;
            cursor: pointer;
          }

          >h3 {
            font-size: 16px;
            color: #333;

            >.tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }

            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }

            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 8px 0;
            line-height: 1;

            >i {
              font-size: 17px;
              padding-right: 10px;
            }
          }
        }

        >div.i-tooltips {
          i {
            font-size: 22px;
            color: #50b5ff;
            cursor: pointer;
          }
        }

        >div.i-remove {
          i {
            font-size: 22px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }
  .RightSidebar {
    .nodata {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }

    .cardStyle {
      padding: 20px 15px;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #000000;

        i {
          font-size: 18px;
          color: #4fb6ff;
        }
      }

      .eventList {
        .event {
          .title {
            color: #333333;
          }

          .dateTime {
            font-size: 12px;
            color: #666666;
          }
        }
      }
    }
  }

  // 添加图标的样式

  .addSchduleTitle {
    color: #333;
  }

  .addSchduleTime {
    color: #ff9b8a;
    padding-top: 5px;
  }

  .processBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
      width: 30px;
      text-align: center;
      cursor: pointer;
    }

    .arrowOpacity {
      cursor: default;
      opacity: 0.4;
    }

    .fixedBox {
      flex: 1;
      overflow: hidden;
    }

    .centerScroll {
      box-sizing: border-box;
      padding: 20px 0;
      white-space: nowrap;

      .signleTab {
        margin-bottom: 0;
        position: relative;
        display: inline-block;

        .leftIcon {
          width: 150px;
          text-align: center;
          cursor: pointer;
        }

        &>.arrowSquare {
          position: absolute;
          top: 25px;
          right: 0;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  ::v-deep .modal-dialog{
    margin-top: 15rem;
  }
  ::v-deep .modal-header{
    font-size: 12px;
  }
  ::v-deep .modal-body{
    font-size: 10px;
    .el-button{
      font-size:10px;
    }
  }
  ::v-deep .nav-tabs .nav-link{
    font-size: 12px;
  }
  .phoneBack{
    .el-button{
      font-size: 10px;
      padding: 12px;
    }
  }
  .all_program_model {
    .event_select {
      background: #f5fbff;
      padding: 1px 15px 0.5px;
      border-radius: 5px;
    }
    .checkBtn{
      min-width: 120px; 
      height: 30px;
      font-size: 10px;
      padding: 7px 10px;
    }
  }

  .program-header {
    display: flex;
    padding: 10px;
    background-color: #f5fbff;
    align-items: center;

    >div i {
      font-size: 10px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    >div p {
      color: #666;
      font-size: 12px;
    }

    >div.program-btn {
      /* display: flex; */
      /* flex-grow: 1; */
      /* justify-content: flex-end; */
      min-width: 150px;
      .el-button{
        width: 70px;
        height: 30px;
        font-size: 12px;
        padding: 6px;
        background: #ffffff;
        border-radius: 2.5px;
      }

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .top-list {
    display: -webkit-box;
    flex-wrap: nowrap;

    div {
      width: 280px;
      height: 100px;
      margin-bottom: 20px;
      margin-right: 10px;
      border-radius: 5px;
      padding: 10px 10px;
      color: #50b5ff;
      background-color: #f5fbff;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      p {
        margin: 0;
        line-height: 30px;
        font-size: 12px;
      }

      p:nth-child(1) {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
      }
    }

    .backGrc {
      background-color: #f5f5f5;
    }

    .backBlue {
      position: absolute;
      top: 0;
      right: 0;
      width: 28px;
      height: 28px;
      padding: 0;
      margin: 0;
      border-top: 15px solid #50b5ff;
      border-right: 15px solid #50b5ff;
      border-left: 15px solid rgba(#000, 0.01);
      border-bottom: 15px solid rgba(#000, 0.01);
    }
  }

  .price {
    >span {
      &:first-child {
        font-size: 12px;
        font-weight: 500;
      }

      &:nth-child(2) {
        font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 12px;
          left: 10px;
        }

        > div {
          display: flex;
          flex-direction: column;
          .link_blue {
            text-decoration: underline;
            color: #50b5ff;
            padding: 0;
            cursor: pointer;
          }

          > h3 {
            font-size: 12px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }
            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }
            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 5px 0;
            font-size: 12px;
            line-height: 1;

            > i {
              font-size: 10px;
              padding-right: 5px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 14px;
            color: #50b5ff;
            cursor: pointer;
          }
        }
        > div.i-remove {
          i {
            font-size: 14px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }
  .phoneSchedule{
    margin-left: 5px;
    .title{
      padding: 6px;
      font-size: 12px;
    }
  }
  .RightSidebar {
    // display: none;
    .nodata {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }

    .cardStyle {
      padding: 20px 15px;

      .title {
        font-size: 10px;
        font-weight: 500;
        color: #000000;

        i {
          font-size: 18px;
          color: #4fb6ff;
        }
      }

      .eventList {
        .event {
          .title {
            color: #333333;
          }

          .dateTime {
            font-size: 10px;
            color: #666666;
          }
        }
      }
    }
  }

  // 添加图标的样式

  .addSchduleTitle {
    color: #333;
  }

  .addSchduleTime {
    color: #ff9b8a;
    padding-top: 5px;
  }

  .processBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
      width: 30px;
      text-align: center;
      cursor: pointer;
    }

    .arrowOpacity {
      cursor: default;
      opacity: 0.4;
    }

    .fixedBox {
      flex: 1;
      overflow: hidden;
    }

    .centerScroll {
      box-sizing: border-box;
      padding: 20px 0;
      white-space: nowrap;

      .signleTab {
        margin-bottom: 0;
        position: relative;
        display: inline-block;

        .leftIcon {
          width: 150px;
          text-align: center;
          cursor: pointer;
        }

        &>.arrowSquare {
          position: absolute;
          top: 25px;
          right: 0;
        }
      }
    }
  }
}
</style>

<style>
  ::v-deep .el-tooltip__popper .is-light {
    border: 1px solid #ddd !important;
  }
</style>